import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';
import Header from '../components/Header';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ExclamationCircleIcon, SpeakerphoneIcon, XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useSession, signIn, SignInResponse } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Footer from '../components/Footer';

type Inputs = {
  email: string;
  password: string;
  'remember-me': boolean;
};

type Error = {
  mobile: string;
  desktop: string;
};

const Login: NextPage = () => {
  const router = useRouter();
  const { data: session } = useSession();
  const [error, setError] = useState<Error | null>(null);
  const [dismissError, setDismissError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const { verified } = router.query;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setDismissError(false);
    const { email, password } = data;
    setError(null);
    // const resultSignIn = await signIn('credentials', { email, password, callbackUrl: router.pathname });
    const resultSignIn = (await signIn('credentials', { username: email, password, redirect: false })) as unknown as SignInResponse;
    if (!resultSignIn) {
      setError({
        mobile: 'Communicate with the admin',
        desktop: 'Error when login, communicate with the admin',
      });
    }

    if (!resultSignIn.error) return;
    console.log('resultSignIn', resultSignIn);

    if (resultSignIn.error === 'CredentialsSignin') {
      setError({
        mobile: 'Review the information entered',
        desktop: 'Review the information entered or your account may not be activated',
      });
    } else if (resultSignIn.error === '/verify-email') {
      router.push('/verify-email');
    } else if (resultSignIn.error) {
      setError({
        mobile: resultSignIn.error,
        desktop: `${resultSignIn.error}, communicate with the admin`,
      });
    }
  };
  // console.log(watch('email')); // watch input value by passing the name of it

  useEffect(() => {
    if (session && session.user) {
      router.push('/');
    }
  }, [router, session]);

  const ariaEmailProps = {
    'aria-invalid': errors.email ? true : false,
    'aria-describedby': errors.email ? 'email-error' : undefined,
  };

  const ariaPasswordProps = {
    'aria-invalid': errors.password ? true : false,
    'aria-describedby': errors.password ? 'password-error' : undefined,
  };

  return (
    <div className='h-full relative'>
      <Head>
        <title>TrackNFTs - Login</title>
        <meta name='description' content='TrackNFTs' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <Header />

      <main>
        {!session && (
          <div className='relative flex flex-col py-12 sm:px-6 lg:px-8'>
            <div className='sm:mx-auto sm:w-full sm:max-w-md'>
              <div className='bg-white py-8 px-4 mx-2 sm:mx-2 shadow rounded-lg sm:px-10'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md relative'>
                  <h2 className='text-center text-3xl font-extrabold text-gray-900'>Enter your account</h2>
                  <p className='mt-2 text-center text-sm text-gray-600'>
                    Or{' '}
                    <Link href='/register'>
                      <a className='font-medium text-red-500 hover:text-red-600'>register an account</a>
                    </Link>
                  </p>
                </div>
                {verified === 'true' && (
                  <div className='mt-4 sm:mx-auto relative sm:w-full sm:max-w-md sm:rounded-lg bg-amber-600'>
                    <div className='mx-auto py-2 px-3 sm:px-6 lg:px-8'>
                      <div className='flex items-center justify-between flex-wrap'>
                        <div className='w-0 flex-1 flex items-center justify-center'>
                          <p className='font-medium text-white text-center truncate'>
                            <span className=''>¡Mail Verified!</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <form className='mt-8 space-y-6' onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                      Email
                    </label>
                    <div className='mt-1 relative'>
                      <input
                        id='email'
                        type='email'
                        autoComplete='email'
                        defaultValue=''
                        required
                        {...ariaEmailProps}
                        {...register('email', { required: true, minLength: 3 })}
                        className={classNames(
                          'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm',
                          errors.email ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 placeholder-gray-400 focus:ring-red-600 focus:border-red-600'
                        )}
                      />
                      {errors.email && (
                        <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                          <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                        </div>
                      )}
                    </div>
                    {/* errors will return when field validation fails  */}
                    {errors.email && (
                      <p className='mt-2 text-sm text-red-600' id='email-error'>
                        This field is required
                      </p>
                    )}
                  </div>

                  <div>
                    <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                      Password
                    </label>
                    <div className='mt-1 relative'>
                      <input
                        id='password'
                        type='password'
                        autoComplete='current-password'
                        defaultValue=''
                        required
                        {...ariaPasswordProps}
                        {...register('password', { required: true, minLength: 8 })}
                        className={classNames(
                          'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm',
                          errors.password ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 placeholder-gray-400 focus:ring-red-600 focus:border-red-600'
                        )}
                      />
                      {errors.password && (
                        <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                          <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                        </div>
                      )}
                    </div>
                    {errors.password && (
                      <p className='mt-2 text-sm text-red-600' id='password-error'>
                        {errors.password.type === 'required' && 'This field is required'}
                        {errors.password.type === 'minLength' && 'This field must have 8 characters'}
                      </p>
                    )}
                  </div>

                  <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                      <input id='remember-me' type='checkbox' {...register('remember-me')} className='h-4 w-4 text-red-500 focus:ring-red-600 border-gray-300 rounded' />
                      <label htmlFor='remember-me' className='ml-2 block text-sm text-gray-900'>
                        Remember Me
                      </label>
                    </div>

                    <div className='text-sm'>
                      <Link href='/forgot-password'>
                        <a className='font-medium text-red-500 hover:text-red-600'>Forgot Password?</a>
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button type='submit' className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'>
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {!dismissError && error && (
          <div className='fixed inset-x-0 bottom-0'>
            <div className='bg-red-600'>
              <div className='max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8'>
                <div className='flex items-center justify-between flex-wrap'>
                  <div className='w-0 flex-1 flex items-center'>
                    <span className='flex p-2 rounded-lg bg-red-800'>
                      <SpeakerphoneIcon className='h-6 w-6 text-white' aria-hidden='true' />
                    </span>
                    <p className='ml-3 font-medium text-white truncate'>
                      <span className='md:hidden'>{error.mobile}</span>
                      <span className='hidden md:inline'>{error.desktop}</span>
                    </p>
                  </div>
                  <div className='order-2 flex-shrink-0 sm:order-3 sm:ml-3'>
                    <button type='button' onClick={() => setDismissError(true)} className='-mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2'>
                      <span className='sr-only'>Remover</span>
                      <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>

      <Footer />
    </div>
  );
};

export default Login;
